import React from 'react';
import { useRouter } from 'next/router';

import requireUser from '../../lib/requireUser';

const Login = () => {
  const router = useRouter();
  const { r } = router.query;
  router.replace(r ?? '/myprofile');
  return <></>;
}

export default requireUser(Login);